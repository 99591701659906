<template>
  <section>
    <b-card title="Blending 01">
      <BlendingSemanal01></BlendingSemanal01>
    </b-card>
    <b-card title="Blending 02">
      <BlendingSemanal02></BlendingSemanal02>
    </b-card>
    <b-card title="Blending 03">
      <BlendingSemanal03></BlendingSemanal03>
    </b-card>
    <b-card title="Blending 04">
      <BlendingSemanal04></BlendingSemanal04>
    </b-card>
  </section>
</template> 
<script>
import {
  BTitle,
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
} from "bootstrap-vue";

import useApiServices from '@/services/useApiServices.js';
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import BlendingSemanal01 from './BlendingSemanal01.vue'
import BlendingSemanal02 from './BlendingSemanal02.vue'
import BlendingSemanal03 from './BlendingSemanal03.vue'
import BlendingSemanal04 from './BlendingSemanal04.vue'



export default {

  components: {
    BTitle,
    BCard,
    AgGridTable,
    BRow,
    BCol,
    BButton,
    BLink,
    BlendingSemanal01,
    BlendingSemanal02,
    BlendingSemanal03,
    BlendingSemanal04,
  },

  data() {
    return {
      detallesPlanificacionBlendingSemanalConfig: useApiServices.detallesPlanificacionBlendingSemanalConfig,
      detallesPlanificacionBlendingSemanalData: useApiServices.detallesPlanificacionBlendingSemanalData,
      detallesPlanificacionEdit: useApiServices.detallesPlanificacionEdit,
      detallesPlanificacionSemanalRecipe: null,

    }
  }, mounted() {

    useApiServices.getRecipeSemanalData(this).then((response) => {
      this.detallesPlanificacionSemanalRecipe = response.data;
    })
      .catch((error) => {
        console.log(error);
      });
  },
};

</script>
<style lang="scss" scoped>
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}

.tg td {
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-v3yz {
  background-color: #f8ff00;
  border-color: #000000;
  text-align: left;
  vertical-align: top;
}

.tg .tg-xwyw {
  border-color: #000000;
  text-align: center;
  vertical-align: middle;
}

.tg .tg-txdf {
  background-color: #f8ff00;
  border-color: #000000;
  text-align: center;
  vertical-align: top;
}

.tg .tg-73oq {
  border-color: #000000;
  text-align: left;
  vertical-align: top;
}
</style>